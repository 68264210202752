import styled from 'styled-components'
import {
  variant,
  SpaceProps,
  space,
  textAlign,
  TextAlignProps,
} from 'styled-system'

import { fonts } from '~/styles/fonts'
import { colors } from '~/styles/colors'

type Props = SpaceProps &
  TextAlignProps & {
    level?: 1 | 2 | 3 | 4 | 5
    bold?: boolean
  } & { className?: string }


export const Heading = styled('div').withConfig({
  shouldForwardProp: (prop) => !['level', 'bold'].includes(prop),
})<Props>(
  {
    fontFamily: fonts.heading.family,
    color: colors.dark,
  },
  variant({
    prop: 'level',
    variants: {
      1: {
        fontSize: '2.25rem',
        fontWeight: fonts.heading.weight,
        lineHeight: 1.6,
      },
      2: {
        fontSize: '1.875rem',
        fontWeight: fonts.heading.weight,
        lineHeight: 1.6,
      },
      3: {
        fontWeight: fonts.text.medium,
        fontSize: '1.5rem',
        lineHeight: 1.6,
      },
      4: {
        fontWeight: fonts.text.medium,
        fontSize: '1.25rem',
        lineHeight: 1.6,
      },
      5: {
        fontWeight: fonts.text.medium,
        fontSize: '1.125rem',
        lineHeight: 1.8,
      },
    },
  }),
  space,
  textAlign,
  props => props.bold && { fontWeight: fonts.heading.weight }
)

Heading.defaultProps = {
  level: 1,
}
